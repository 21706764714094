@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'Satoshi';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

.heading_1 {
    color: var(--Gray-1, #333);
    font-family: "DM Sans";
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3125rem;
}

.heading_sub {
    color: var(--Gray-1, #333);
    font-family: "DM Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.75rem */
}

.step_header_txt {
    color: var(--White, #FFF);
    font-family: "DM Sans";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.125rem;
    /* 154.545% */
}

.step_desc_txt {
    color: #C6E1FF;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 125% */
}

.review_header_txt {
    color: var(--Gray-800, #1D2939);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    /* 155.556% */
}

.review_desc_txt {
    color: var(--Gray-400, #98A2B3);
    text-align: center;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 1.2rem */
}

.profile_header {
    color: #171A1F;
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.575rem */
}

.profile_sub {
    color: #171A1F;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.4rem */
}

.profile_p {
    color: #171A1F;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.4rem */
}


.profile-card {
    border-radius: 0.5rem;
    background: #F8FAFF;
    box-shadow: 0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07);
}

.mngt-tool {
    color: #171A1F;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.mngt-details-black {
    color: var(--Gray-800, #1D2939);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}

.mngt-details-blue {
    color: var(--FMW-Primary, #0170BD);
    text-align: center;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.mngt-card-header {
    color: var(--Gray-900, #101828);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.mngt-card-file {
    color: var(--FMW-Primary, #0170BD);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-decoration-line: underline;
}

.mngt-minus {
    border-radius: 0.25rem;
    border: 1px solid #000;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.wtr-srce-title {
    color: var(--Black, #000);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.wtr-srce-content {
    color: var(--Gray-500, #667085);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.remove-blue {
    color: var(--FMW-Primary, #0170BD);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.download-btn {
    border-radius: 0.5rem;
    border: 1px dashed #9095A0;
    background: var(--Blue-50, #EFF8FF);
    box-shadow: 0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07);
    width: 100%;
    height: 3.75rem;
    padding: 1.25rem 1.5625rem 1.25rem 1.1875rem;
}

.offer-title {
    color: var(--Gray-1, #333);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3125rem;
    text-transform: capitalize;
}


.dvt-title {
    color: var(--Black-Default, #000);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.575rem */
}

.dvt-date {
    color: #000;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3125rem;
    /* 150% */
}

.dvt-details {
    color: #000;
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    /* 150% */
    text-transform: capitalize;
}

.inv-card {
    border: 1px solid #E6EAEF;
    background: var(--White, #FFF);
    width: 23.5625rem;
    height: 6.375rem;
}

.inv-status {
    color: #078247;
    font-family: "DM Sans";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.1375rem */
}

.inv-status-desc {
    color: #4F5A69;
    font-family: "DM Sans";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.1375rem */
}

.inv-paid {
    color: #4F5A69;
    font-family: "DM Sans";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.05rem */
}

.inv-file {
    color: var(--FMW-Primary, #0170BD);
    font-family: "DM Sans";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    /* 123.529% */
}

.nav_item_txt {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    /* 131.25% */
}

.landing_h1_txt {
    color: #2B2B2E;
    font-family: "DM Sans";
    font-size: 4.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 5.1rem */
}

.landing_h2_txt {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 2.1rem */
}

.landing_num_txt {
    color: var(--FMW-Primary, #0170BD);
    font-family: "Inter" sans-serif;
    font-size: 7.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 9.14513rem;
    /* 124.002% */
}

.landing_num_sub_txt {
    color: var(--text-black, #222);
    text-align: center;
    font-family: "DM Sans";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 1.95rem */
}

.how_to_header_txt {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.how_to_step_txt {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.how_to_step_sub_txt {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.key_features_header_txt {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.key_features_txt {
    color: var(--text-black, #222);
    text-align: justify;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.4rem */
}

.need_water_header_txt {
    color: var(--Blue-White, #F4FCFF);
    font-family: "DM Sans";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 2.4rem */
}

.need_water_txt {
    color: var(--Blue-White, #F4FCFF);
    font-family: "DM Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 1.5rem */
}

.trusted_header_txt {
    color: #C7C7C7;
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 1.35rem */
}

.user_comment_dark_txt {
    color: var(--text-black, #222);
    text-align: justify;
    font-family: "DM Sans";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.925rem */
}

.user_comment_light_txt {
    color: #C0C0C0;
    text-align: justify;
    font-family: "DM Sans";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.user_comment_sm_light_txt {
    color: #989898;
    text-align: justify;
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.faq_header_txt {
    color: var(--FMW-Primary, #0170BD);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.1875rem;
}

.faq_panel_header_txt {
    color: var(--text-black, #222);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 1.3125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}

.faq_panel_txt {
    color: var(--Blue-grey, #7E93AE);
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
}

.need_help_header_txt {
    color: #424242;
    font-family: "DM Sans";
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 2.1rem */
    letter-spacing: -0.0175rem;
}

.need_help_txt {
    color: #000;
    font-family: "Inter" sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.footer_header_txt {
    color: var(--White, #FFF);
    font-family: "Inter" sans-serif;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    opacity: 0.4;
}

.footer_txt {
    color: var(--White, #FFF);
    font-family: "Inter" sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    opacity: 0.6;
}

.footer_txt_end {
    color: var(--White, #FFF);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    opacity: 0.4;
}

.no_data_header {
    color: #333;
    text-align: center;
    font-family: "DM Sans";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.no_data_subheader {
    color: var(--text-black, #222);
    text-align: center;
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.timeline_header {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
}

.timeline_person {
    color: #78CC9B;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
}

.timeline-content {
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
    text-transform: capitalize;
}

.password-p {
    color: #565E6C;
    text-align: center;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.password-contain {
    color: var(--Text-Secondary, #616161);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.heading_5 {
    color: var(--text-black, #222);
    text-align: center;
    font-family: "DM Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 1.5rem */
}
.partner-sub{
    color: var(--Field-text, #565E6C); 
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;  
}
.partner-card{
    border-radius: 0.5rem;
    background: #FFF;
    box-shadow: 0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07);
}
.indicator-title{
    color: #171A1F;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;  
}
.ind-h1{
    color: #171A1F; 
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;  
}
.ind-sub{
    color: #171A1F; 
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
